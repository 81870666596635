import { useRouter } from "next/router";
import { SeeHowButtonContainer } from "./styles";

import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

interface ButtonProps {
  text?: string;
  naviagteTo?: string;
  width?: string;
  height?: string;
  margin?: string;
  fontSize?: string;
  mobileHeight?: string;
  mobileWidth?: string;
  mobileMargin?: string;
  mobileFontSize?: string;
  svghHeight?: string;
  mobileSvgHeight?: string;
  background?: string;
  marginText?: string;
  target?: string;
  disableRouting?: boolean;
  fontColor?: string;
  fontWeight?: string;
  border?: string;
  handleOnClick?: () => void;
}

export const SeeHowButton = (props: ButtonProps) => {
  const router = useRouter();

  useEffect(() => {
    // AOS.init({
    //   duration: 1000,
    // });
  }, []);

  function isFullUrl(url: string) {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  }

  const handleClick = () => {
    if (!props.disableRouting) {
      if (props.target === "_blank") {
        if (router.locale == "" || router.locale == "en") {
          window.open(`${props.naviagteTo}`, "_blank");
        } else {
          if (isFullUrl(props.naviagteTo || "")) {
            window.open(`${props.naviagteTo}`, "_blank");
          } else {
            window.open(`${router.locale}${props.naviagteTo}`, "_blank");
          }
        }
      } else {
        router.push(`${props.naviagteTo}`);
      }
    }
  };

  return (
    <SeeHowButtonContainer
      width={props.width}
      height={props.height}
      fontSize={props.fontSize}
      onClick={props.handleOnClick || handleClick}
      margin={props.margin}
      mobileFontSize={props.mobileFontSize}
      mobileHeight={props.mobileHeight}
      mobilewidth={props.mobileWidth}
      mobileMargin={props.mobileMargin}
      svghHeight={props.svghHeight}
      mobileSvgHeight={props.mobileSvgHeight}
      background={props.background}
      marginText={props.marginText}
      fontColor={props.fontColor}
      fontWeight={props.fontWeight}
      border={props.border}
    >
      <div className="text-sec">{props.text}</div>
      <div className="arrow-container">
        <svg
          width={props.width ? props.height : "50"}
          height={props.height ? props.height : "51"}
          viewBox="0 0 67 67"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="33.2806"
            cy="33.8373"
            r="29.9817"
            transform="rotate(-5.9082 33.2806 33.8373)"
            fill="#F93D66"
          />
          <path
            d="M22.5752 34.1689L42.9975 34.1689"
            stroke="white"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M35.6084 41.9888L43.4297 34.1675L35.6084 26.3462"
            stroke="white"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </SeeHowButtonContainer>
  );
};
