import anime from "animejs";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { useEffect } from "react";
const shortid = require("shortid");
import { useIsomorphicLayoutEffect } from "../../../pages";
import { DesktopView, MobileContent } from "../../common/common-styles";
import {
  LangImg,
  LanguagesCard,
  LanguagesCardWrapper,
  LanguagesContainer,
  Smalltext,
  SmallTextWrapper,
  TextContent,
} from "./styles";
import Image from "next/image";
import { LandingPageTextAnimation } from "../../common/landing-pages-text-animation";
import { useRouter } from "next/router";

gsap.registerPlugin(ScrollTrigger);

interface LanguagesProps {
  data: LanguageData[];
  shouldPin: boolean;
  pinStart: string;
  secondLineText: string;
  thirdLineText: string;
}

interface LanguageData {
  title: string;
  mobileBackground: string;
  desktopBackground: string;
  desktopLsImage: string;
  mobileLsImageAltText: string;
}

export const textCss: any = {
  enBold: `font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 250px;
  line-height: 6px;
  display: flex;
  align-items: center;
  letter-spacing: -0.045em;
  color: #ffffff;
  margin-top: -10vh;
  @media screen and (min-width: 260px) and (max-width: 520px) {
    position: absolute;
    bottom: -18px;
    left: 24px;
    font-style: normal;
    font-weight: 700;
    font-size: 46px;
    line-height: 35px;
    display: flex;
    align-items: center;
    /* letter-spacing: 0.03em; */
    color: #ffffff;
    margin-top: 0px;
  }
  @media screen and (min-width: 520px) and (max-width: 1060px) {
    position: absolute;
    bottom: -28px;
    left: 4vh;
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 66px;
    display: flex;
    align-items: center;
    /* letter-spacing: 0.03em; */
    color: #ffffff;
    margin-top: 0px;
  }`,
  idBold: `
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 250px;
  line-height: 6px;
  display: flex;
  align-items: center;
  letter-spacing: -0.045em;
  color: #ffffff;
  margin-top: -10vh;
  @media screen and (min-width: 260px) and (max-width: 520px) {
    position: absolute;
    bottom: -18px;
    left: 24px;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 25px;
    display: flex;
    align-items: center;
    /* letter-spacing: 0.03em; */
    color: #ffffff;
    margin-top: 0px;
  }
  @media screen and (min-width: 520px) and (max-width: 1060px) {
    position: absolute;
    bottom: -28px;
    left: 4vh;
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 56px;
    display: flex;
    align-items: center;
    /* letter-spacing: 0.03em; */
    color: #ffffff;
    margin-top: 0px;
  }
  `,
  usBold: `font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 250px;
  line-height: 6px;
  display: flex;
  align-items: center;
  letter-spacing: -0.045em;
  color: #ffffff;
  margin-top: -10vh;
  @media screen and (min-width: 260px) and (max-width: 520px) {
    position: absolute;
    bottom: -18px;
    left: 24px;
    font-style: normal;
    font-weight: 700;
    font-size: 46px;
    line-height: 35px;
    display: flex;
    align-items: center;
    /* letter-spacing: 0.03em; */
    color: #ffffff;
    margin-top: 0px;
  }
  @media screen and (min-width: 520px) and (max-width: 1060px) {
    position: absolute;
    bottom: -28px;
    left: 4vh;
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 66px;
    display: flex;
    align-items: center;
    /* letter-spacing: 0.03em; */
    color: #ffffff;
    margin-top: 0px;}
    
  `,
};

export const Languages = (props: LanguagesProps) => {
  const router = useRouter();
  useIsomorphicLayoutEffect(() => {
    let timeline: any;
    let scrollTrigger: any;
    const matchMedia = gsap.matchMedia();
    matchMedia.add(
      {
        isMobile: `(max-width: 1060px)`,
        isDesktop: `(min-width: 1060px)`,
      },
      (mediaContext: any) => {
        const { isMobile } = mediaContext.conditions;
        if (isMobile) {
          ScrollTrigger.create({
            trigger: ".languages-container",
            start: "top center",
            end: "bottom center",
            onEnter: () => {
              anime.timeline({ loop: false }).add({
                targets: ".letter-lang",
                translateX: [40, 0],
                translateZ: 0,
                opacity: [0, 1],
                easing: "easeOutExpo",
                duration: 600,
                delay: (el, i) => 350 + 10 * i,
              });
            },
          });

          let arr: any[] = gsap.utils.toArray(".languages-card");
          gsap.set(arr[0], {
            opacity: 1,
          });

          let timeline = gsap.timeline({ paused: true, repeat: -1 });

          arr.forEach((el: any, index) => {
            if (index != arr.length - 1) {
              timeline
                .to(arr[index + 1], {
                  duration: 1,
                })
                .to(arr[index], {
                  opacity: 0,
                  duration: 1,
                })
                .to(arr[index + 1], {
                  opacity: 1,
                  duration: 1,
                  delay: -1,
                });
            }
          });

          scrollTrigger = ScrollTrigger.create({
            trigger: ".languages-container",
            start: "top center",
            end: `+=70%`,
            pin: false,
            pinType: "fixed",
            scrub: false,
            invalidateOnRefresh: true,
            animation: timeline,
            toggleActions: "play pause resume pause",
            anticipatePin: 1,
          });
        } else {
          matchMedia.revert();
        }
      }
    );

    return () => {
      timeline && timeline.kill();
      matchMedia && matchMedia.revert();
    };
  }, []);

  const generateLanguages = () => {
    return props.data.map((language, index) => {
      let key = shortid.generate();
      return (
        <LanguagesCard
          key={key}
          className={`languages-card-${index} languages-card`}
          background={props?.data[index]?.mobileBackground}
          desktopBackground={props?.data[index]?.desktopBackground}
          zIndex={props?.data.length - index}
        >
          <Image
            width={1300}
            height={1400}
            loading="lazy"
            src={language.mobileBackground}
            style={{
              objectFit: "cover",
              borderRadius: "0px 0px 32px 32px ",
              width: "100vw",
              height: "70vh",
            }}
            quality={100}
            alt={language.mobileLsImageAltText}
          />
          <TextContent
            styles={textCss[`${router.locale}Bold`]}
            className="languages-text-content"
            dangerouslySetInnerHTML={{ __html: language.title }}
          ></TextContent>
        </LanguagesCard>
      );
    });
  };

  const splitText = (text: string) => {
    let splitText = text.split("");
    return splitText.map((text, index: any) => {
      let res =
        text === " " ? (
          <span className="letter-lang" key={shortid.generate()}>
            &nbsp;
          </span>
        ) : (
          <span className="letter-lang" key={shortid.generate()}>
            {text}
          </span>
        );
      return res;
    });
  };

  // Glance speaks your language

  return (
    <LanguagesContainer className="languages-container" id="seventh">
      <LanguagesCardWrapper>{generateLanguages()}</LanguagesCardWrapper>
      <MobileContent>
        <LandingPageTextAnimation
          firstLine={""}
          secondLine={props.secondLineText}
          thirdLine={props.thirdLineText}
          textAnimationClassName={`letter-lang`}
          textContainerClassName={`letter-lang-container`}
          margin="36px 16px 0px 27px"
        />
      </MobileContent>
      <DesktopView>
        <SmallTextWrapper>
          <Smalltext>{splitText(props.secondLineText)}</Smalltext>
          <Smalltext>{splitText(props.thirdLineText)}</Smalltext>
        </SmallTextWrapper>
      </DesktopView>
    </LanguagesContainer>
  );
};
