import styled from "styled-components";

interface CategoriesTabletContainerProps {
  background?: string;
  zIndex?: number | string;
}

export const CategoriesTabletContainer = styled.div`
  display: none;
  @media (min-width: 520px) and (max-width: 1060px) {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const BannerBackground = styled.div<CategoriesTabletContainerProps>`
  display: none;
  @media (min-width: 520px) and (max-width: 1060px) {
    display: block;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-image: ${(props) => `url(${props.background})`};
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: ${(props) => props.zIndex || 0};
    opacity: 0;
  }
`;

export const MobileOutline = styled.img`
  position: absolute;
  width: 38vh;
  z-index: 98;
  margin-top: 4.5vh;
  margin-left: 0vh;
`;

export const LockscreenImage = styled.picture<any>`
  position: absolute;
  width: 33.75vh;
  z-index: ${(props) => props.zIndex};
  img {
    width: 35.9vh;
    border-radius: 4vh;
    margin-top: 5vh;
    margin-left: -1.1vh;
  }
`;

export const Content = styled.div<any>`
  position: absolute;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 62px;
  line-height: 62px;
  text-align: center;
  letter-spacing: 1px;
  color: #ffffff;
  z-index: ${(props) => props.zIndex};
  opacity: 0;
  margin-top: 13vh;

  @media screen and (min-width: 960px) and (max-width: 1300px) {
    font-size: 62px;
  }

  @media screen and (min-width: 720px) and (max-width: 960px) {
    font-size: 52px;
  }

  @media screen and (min-width: 300px) and (max-width: 720px) {
    font-size: 58px;
  }
`;

export const TimeSection = styled.div`
  position: absolute;
  margin-bottom: 64vh;
  width: 10vh;
  z-index: 198;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  .lock-img {
    margin-top: 6rem;
    width: 21px;
    transform: translateY(0px);
  }
  .time-detail {
    .time {
      font-size: 48px;
      font-family: "Inter";
    }
    .date {
      font-size: 12px;
      font-family: "Inter";
      text-align: center;
    }
  }
`;

export const WidgetContainer = styled.div`
  position: absolute;
  width: 34vh;
  overflow: hidden;
  min-height: 12vh;
  z-index: 10;
  display: flex;
  /* justify-content: space-between; */
  z-index: 220;
  /* top: 74vh;
  left: 37.25vh; */
  /* transform: translate(-2.25vh); */
  transform: translate(0.3vh, 30vh);
`;

export const LeftWidgetContainer = styled.div<any>`
  width: 9vh;
  height: 9vh;
  #content-container-header-widget-tablet {
    height: 100%;
    width: 9vh;
    float: right;
    padding: 5px;
    perspective: 400;
    -webkit-perspective: 400;
    color: white;
  }

  #widget-seconds-tablet {
    width: 9vh;
    height: 9vh;
    float: left;
    transform-style: preserve-3d;
  }

  .widget-face-tablet {
    z-index: 100;
    position: absolute;
    width: 9vh;
    height: 9vh;
    border-radius: 12px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    line-height: 32px;
    text-align: center;
    -webkit-backface-visibility: hidden; /* Chrome, Safari, Opera */
    backface-visibility: hidden;
  }

  .widget-face-front-tablet {
    transform: translate3d(0vh, 0, 4vh);
  }

  .widget-face-back-tablet {
    transform: rotate3d(0, 1, 0, 180deg) rotate3d(0, 0, 1, 180deg)
      translate3d(0vh, 0, 4vh);
  }

  .widget-face-bottom-tablet {
    transform: rotate3d(1, 0, 0, -90deg) translate3d(0vh, 0, 4vh);
  }

  .widget-face-top-tablet {
    transform: rotate3d(1, 0, 0, 90deg) translate3d(0vh, 0, 4vh);
  }

  .widget-face-right-tablet {
    background-color: rgba(0, 0, 0, 0.9);
    transform: rotate3d(0, 1, 0, 90deg) translate3d(0vh, 0, 4vh);
  }

  .widget-face-left-tablet {
    background-color: rgba(0, 0, 0, 0.9);
    transform: rotate3d(0, 1, 0, -90deg) translate3d(0vh, 0, 4vh);
  }
`;

export const RightWidgetContainer = styled.div<any>`
  /* width: 45vw; */
  height: 9vh;
  transform: translateX(4vh);
  #content-container-header-widget-right-tablet {
    height: 100%;
    width: 174px;
    float: right;
    padding: 5px;
    perspective: 400;
    -webkit-perspective: 400;
    color: white;
  }

  #widget-seconds-right-tablet {
    /* width: 45vw; */
    height: 9vh;
    float: left;
    transform-style: preserve-3d;
  }

  .widget-face-right-tablet {
    z-index: 100;
    position: absolute;
    width: 19.5vh;
    height: 9vh;
    border-radius: 12px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    line-height: 32px;
    text-align: center;
    -webkit-backface-visibility: hidden; /* Chrome, Safari, Opera */
    backface-visibility: hidden;
  }

  .widget-face-front-right-tablet {
    transform: translate3d(-1vh, 0, 4vh);
  }

  .widget-face-back-right-tablet {
    transform: rotate3d(0, 1, 0, 180deg) rotate3d(0, 0, 1, 180deg)
      translate3d(-1vh, 0, 4vh);
  }

  .widget-face-bottom-right-tablet {
    transform: rotate3d(1, 0, 0, -90deg) translate3d(-1vh, 0, 4vh);
  }

  .widget-face-top-right-tablet {
    transform: rotate3d(1, 0, 0, 90deg) translate3d(-1vh, 0, 4vh);
  }

  .widget-face-right-right-tablet {
    background-color: rgba(0, 0, 0, 0.9);
    transform: rotate3d(0, 1, 0, 90deg) translate3d(-1vh, 0, 4vh);
  }

  .widget-face-left-right-tablet {
    background-color: rgba(0, 0, 0, 0.9);
    transform: rotate3d(0, 1, 0, -90deg) translate3d(-1vh, 0, 4vh);
  }
`;
