import styled from "styled-components";

interface CardProps {
  background: string;
  desktopBackground: string;
  zIndex: number;
}

export const LanguagesContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;

  .letter-lang {
    display: inline-block;
  }

  @media (max-width: 1060px) {
    margin-bottom: 32px;
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(160, 29, 146, 0.6) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    margin-bottom: 40px;
  }
  @media screen and (min-width: 1060px) {
    display: flex;
  }
`;

export const LanguagesCardWrapper = styled.div`
  display: flex;
  @media (max-width: 1060px) {
    flex-direction: column;
    width: 100vw;
    height: 70vh;
    position: relative;
  }
`;

export const LanguagesCard = styled.div<CardProps>`
  display: none;

  @media (max-width: 1060px) {
    position: absolute;
    z-index: ${(props) => props.zIndex};
    display: flex;

    width: 100vw;
    height: 70vh;
    opacity: 0;
  }
`;

export const LangImg = styled.div<any>`
  display: none;
  @media (max-width: 1060px) {
    display: block;
    position: absolute;
    background-image: ${(props) => `url(${props.background})`};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 70vh;
    width: 100vw;
    border-radius: 0px 0px 32px 32px;
  }
`;

export const TextContent = styled.div<any>`
  ${(props) => props.styles};
`;

export const SmallTextWrapper = styled.div`
  align-self: center;
  margin-left: 72vw;
  position: absolute;
  margin-top: calc(45vh + 125px);
`;

export const Smalltext = styled.div`
  font-family: "Inter";
  @media (max-width: 520px) {
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 29px;
    letter-spacing: 0.03em;
    color: #ffffff;
    margin-left: 24px;
    margin-top: 4px;
  }

  @media screen and (min-width: 520px) and (max-width: 1060px) {
    font-style: normal;
    font-weight: 400;
    font-size: 34px;
    line-height: 43px;
    letter-spacing: 0.03em;
    color: #ffffff;
    margin-left: 4vh;
    margin-top: 4px;
  }
`;
