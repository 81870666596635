/* eslint-disable @next/next/no-img-element */
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { useIsomorphicLayoutEffect } from "../../../pages";
import {
  BannerBackground,
  CategoriesTabletContainer,
  Content,
  LeftWidgetContainer,
  LockscreenImage,
  MobileOutline,
  RightWidgetContainer,
  // RightWidgetContainer,
  TimeSection,
  WidgetContainer,
} from "./styles";
import { CategoryData } from "../lockscreen-cards";
import Image from "next/image";

interface CategoriesTabletProps {
  data: CategoryData[];
}

const cardsData = [
  {
    background:
      "https://web.glance-cdn.com/images/glance-website/corp/lockscreen/test/cat-blur/sports.webp",

    lockscreenUrl:
      "https://web.glance-cdn.com/images/glance-website/corp/lockscreen/test/cat-blur/sports-ls.webp",
    title: "LIVE Sports",
  },
  {
    background:
      "https://web.glance-cdn.com/images/glance-website/corp/lockscreen/test/cat-blur/games.webp",
    lockscreenUrl:
      "https://web.glance-cdn.com/images/glance-website/corp/lockscreen/test/cat-blur/games-ls.webp",
    title: "Play and Watch Games",
  },
  {
    background:
      "https://web.glance-cdn.com/images/glance-website/corp/lockscreen/test/cat-blur/shopping.webp",
    lockscreenUrl:
      "https://web.glance-cdn.com/images/glance-website/corp/lockscreen/test/cat-blur/shopping-ls.webp",
    title: "Shop Trending Fashion",
  },
  {
    background:
      "https://web.glance-cdn.com/images/glance-website/corp/lockscreen/test/cat-blur/trends.webp",
    lockscreenUrl:
      "https://web.glance-cdn.com/images/glance-website/corp/lockscreen/test/cat-blur/trends-ls.webp",
    title: "Latest Trends",
  },
  {
    background:
      "https://web.glance-cdn.com/images/glance-website/corp/lockscreen/test/cat-blur/ent.webp",
    lockscreenUrl:
      "https://web.glance-cdn.com/images/glance-website/corp/lockscreen/test/cat-blur/ent-ls.webp",
    title: "LIVE Entertainment",
  },
];

let urls = [
  "https://web.glance-cdn.com/images/glance-website/corp/lockscreen/categories/widgets/sports-left.webp",
  "https://web.glance-cdn.com/images/glance-website/corp/lockscreen/categories/widgets/play-left.webp",
  "https://web.glance-cdn.com/images/glance-website/corp/lockscreen/categories/widgets/shop-left.webp",
  "https://web.glance-cdn.com/images/glance-website/corp/lockscreen/categories/widgets/trends-left.webp",
  "https://web.glance-cdn.com/images/glance-website/corp/lockscreen/categories/widgets/ent-left.webp",
];

let rightUrls = [
  "https://web.glance-cdn.com/images/glance-website/corp/lockscreen/categories/widgets/sports-right.webp",
  "https://web.glance-cdn.com/images/glance-website/corp/lockscreen/categories/widgets/play-right.webp",
  "https://web.glance-cdn.com/images/glance-website/corp/lockscreen/categories/widgets/shop-right.webp",
  "https://web.glance-cdn.com/images/glance-website/corp/lockscreen/categories/widgets/trends-right.webp",
  "https://web.glance-cdn.com/images/glance-website/corp/lockscreen/categories/widgets/ent-right.webp",
];

export const CategoriesTablet = (props: CategoriesTabletProps) => {
  let i = 0;
  let flag = "widget-face-front-tablet";
  let flag2 = "widget-face-front-right-tablet";
  const days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
  const months = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];

  useIsomorphicLayoutEffect(() => {
    let activeIndex = i;
    i++;
    let testFlag = flag;
    startTime(testFlag, 2, urls[0], 0, activeIndex + 1);
    startTimeRight(flag2, 2, rightUrls[0], 0, activeIndex + 1);
  }, []);

  useIsomorphicLayoutEffect(() => {
    let context = gsap.context(() => {
      const matchMedia = gsap.matchMedia();
      const handleMatchMedia = (mediaContext: any) => {
        const { isTablet } = mediaContext.conditions;
        if (isTablet) {
          const bannerBackgrounds: any[] = gsap.utils.toArray(
            ".tab-background-banner"
          );
          const lockscreenCards: any[] = gsap.utils.toArray(
            ".tab-lockscreen-card"
          );
          const contentCards: any[] = gsap.utils.toArray(".tab-content-card");
          let timeline = gsap.timeline({ paused: true });
          timeline
            .set(contentCards[0], {
              opacity: 1,
              duration: 0,
            })
            .set(bannerBackgrounds[0], {
              opacity: 1,
              duration: 0,
            });

          // timeline.to(bannerBackgrounds[0], {
          //   duration: 1,
          // });

          props.data.forEach((card, index) => {
            if (index != props.data.length - 1) {
              timeline
                .to(bannerBackgrounds[index + 1], {
                  duration: 0.5,
                  opacity: 1,
                })
                .to(bannerBackgrounds[index], {
                  duration: 1,
                  opacity: 0,
                  ease: "none",
                  delay: 0.5,
                })
                .to(lockscreenCards[index], {
                  duration: 1,
                  opacity: 0,
                  ease: "none",
                  delay: -1,
                })
                .to(contentCards[index], {
                  opacity: 0,
                  duration: 0.5,
                  delay: -1,
                })
                .to(contentCards[index + 1], {
                  opacity: 1,
                  duration: 0.5,
                  delay: -0.5,
                  onStart: () => {
                    let idx = index;
                    let activeIndex = i;
                    i++;
                    let testFlag = flag;
                    startTime(
                      testFlag,
                      2,
                      urls[idx + 1],
                      idx + 1,
                      activeIndex + 1
                    );
                    startTimeRight(
                      flag2,
                      2,
                      rightUrls[idx + 1],
                      idx + 1,
                      activeIndex + 1
                    );
                  },
                  onReverseComplete: () => {
                    let testFlag = flag;
                    let idx = index;
                    let activeIndex = i;
                    i++;
                    startTime(testFlag, 2, urls[idx], idx, activeIndex + 1);
                    startTimeRight(
                      flag2,
                      2,
                      rightUrls[idx],
                      idx,
                      activeIndex + 1
                    );
                  },
                });
            }
          });

          ScrollTrigger.create({
            trigger: ".categories-tablet-container",
            start: "top 1px",
            end: `+=${window.innerHeight * (props.data.length - 1) + 2}px`,
            pin: true,
            pinSpacing: true,
            scrub: true,
            invalidateOnRefresh: true,
            animation: timeline,
          });
        } else {
          matchMedia.revert();
        }
      };

      matchMedia.add(
        {
          isMobile: `(max-width: 520px)`,
          isTablet: `(max-width: 1060px)`,
          isDesktop: `(min-width: 1060px)`,
        },
        handleMatchMedia
      );
    });

    return () => {
      context.revert();
    };
  }, []);

  const timeDate = () => {
    let date = new Date();
    let hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    let minutes =
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    let day = date.getDay();
    let month = date.getMonth();
    let exactDate = date.getDate();

    return (
      <>
        <div className="time">{hours + ":" + minutes}</div>
        <div className="date">
          {days[day] + ", " + exactDate + " " + months[month]}
        </div>
      </>
    );
  };

  const generateBackgroundBanners = () => {
    return props.data.map((card, index) => {
      return (
        <BannerBackground
          key={index}
          className="tab-background-banner"
          background={card.desktopBackground}
          zIndex={props.data.length - index}
        ></BannerBackground>
      );
    });
  };

  const generateLockscreenCards = () => {
    return props.data.map((card, index) => {
      return (
        <LockscreenImage
          key={index}
          className="tab-lockscreen-card"
          zIndex={99 + props.data.length - index}
        >
          <source
            srcSet={card.desktopLsImage}
            type="image/webp"
            media="(min-width: 520px)"
          />
          <img
            src={
              "https://web.glance-cdn.com/images/glance-website/corp/lockscreen/cat-cards/sports-lat.webp"
            }
            alt=""
          />
        </LockscreenImage>
      );
    });
  };

  const generateContent = () => {
    return props.data.map((card, index) => {
      return (
        <Content
          className={"tab-content-card"}
          key={index}
          zIndex={150 + props.data.length - index}
        >
          {card.title}
        </Content>
      );
    });
  };

  function startTime(
    secFace: any,
    lastSec: any,
    bgUrl: any,
    index: any,
    rotationIdx: any
  ) {
    try {
      let prevData: any = "";
      let test = gsap.utils.selector(`#widget-seconds-tablet`);
      let test2 = test(`.${secFace}`);
      prevData = test2[0].getAttribute("data-prev");
      let tes = test(`.${prevData}`);
      tes[0].setAttribute("src", bgUrl);
      tes[0].style.objectFit = "cover";
      gsap.to("#widget-seconds-tablet", {
        rotationX: `+${rotationIdx * 90}`,
        duration: 0.5,
      });
      flag = prevData;
    } catch (e) {
      console.log(e);
    }
  }

  function startTimeRight(
    secFace: any,
    lastSec: any,
    bgUrl: any,
    index: number,
    rotationIdx: any
  ) {
    let prevData: any = "";
    let test = gsap.utils.selector(`#widget-seconds-right-tablet`);
    let test2 = test(`.${secFace}`);
    prevData = test2[0]?.getAttribute("data-prev");
    let tes = test(`.${prevData}`);
    tes[0].setAttribute("src", bgUrl);
    tes[0].style.objectFit = "cover";
    gsap.to("#widget-seconds-right-tablet", {
      rotationX: `+${rotationIdx * 90}`,
      duration: 0.5,
    });
    flag2 = prevData;
  }

  return (
    <CategoriesTabletContainer className="categories-tablet-container">
      {generateBackgroundBanners()}
      <MobileOutline src="https://web.glance-cdn.com/images/glance-website/corp/lockscreen/desktop/value-props/mobile-outline.png" />
      {generateLockscreenCards()}
      {generateContent()}
      <TimeSection>
        <img className="lock-img" src="/lock.png"></img>
        <div className="time-detail">{timeDate()}</div>
      </TimeSection>

      <WidgetContainer className="widget-container-test-tablet">
        <LeftWidgetContainer>
          <div id="content-container-header-widget-tablet">
            <div id="widget-seconds-tablet">
              <Image
                src=""
                alt="cube"
                fill={true}
                loading="lazy"
                className="widget-face-tablet widget-face-front-tablet"
                data-next="widget-face-top-tablet"
                data-prev="widget-face-bottom-tablet"
              ></Image>
              <Image
                src=""
                alt="cube"
                fill={true}
                loading="lazy"
                className="widget-face-tablet widget-face-bottom-tablet"
                data-next="widget-face-front-tablet"
                data-prev="widget-face-back-tablet"
              ></Image>
              <Image
                src=""
                alt="cube"
                fill={true}
                loading="lazy"
                className="widget-face-tablet widget-face-left-tablet"
              ></Image>
              <Image
                src=""
                alt="cube"
                fill={true}
                loading="lazy"
                className="widget-face-tablet widget-face-right-tablet"
              ></Image>
              <Image
                src=""
                alt="cube"
                fill={true}
                loading="lazy"
                className="widget-face-tablet widget-face-back-tablet"
                data-next="widget-face-bottom-tablet"
                data-prev="widget-face-top-tablet"
              ></Image>
              <Image
                src=""
                alt="cube"
                fill={true}
                loading="lazy"
                className="widget-face-tablet widget-face-top-tablet"
                data-next="widget-face-back-tablet"
                data-prev="widget-face-front-tablet"
              ></Image>
            </div>
          </div>
        </LeftWidgetContainer>
        <RightWidgetContainer>
          <div id="content-container-header-widget-right-tablet">
            <div id="widget-seconds-right-tablet">
              <Image
                src=""
                alt="cube"
                fill={true}
                loading="lazy"
                className="widget-face-right-tablet widget-face-front-right-tablet"
                data-next="widget-face-top-right-tablet"
                data-prev="widget-face-bottom-right-tablet"
              ></Image>
              <Image
                src=""
                alt="cube"
                fill={true}
                loading="lazy"
                className="widget-face-right-tablet widget-face-bottom-right-tablet"
                data-next="widget-face-front-right-tablet"
                data-prev="widget-face-back-right-tablet"
              ></Image>
              <Image
                src=""
                alt="cube"
                fill={true}
                loading="lazy"
                className="widget-face-right-tablet widget-face-left-right-tablet"
              ></Image>
              <Image
                src=""
                alt="cube"
                fill={true}
                loading="lazy"
                className="widget-face-right-tablet widget-face-right-right-tablet"
              ></Image>
              <Image
                src=""
                alt="cube"
                fill={true}
                loading="lazy"
                className="widget-face-right-tablet widget-face-back-right-tablet"
                data-next="widget-face-bottom-right-tablet"
                data-prev="widget-face-top-right-tablet"
              ></Image>
              <Image
                src=""
                alt="cube"
                fill={true}
                loading="lazy"
                className="widget-face-right-tablet widget-face-top-right-tablet"
                data-next="widget-face-back-right-tablet"
                data-prev="widget-face-front-right-tablet"
              ></Image>
            </div>
          </div>
        </RightWidgetContainer>
      </WidgetContainer>
    </CategoriesTabletContainer>
  );
};
