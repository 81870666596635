import { BackgroundGlowContainer } from "./styles";

interface BackgroundGlowProps {
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
  width?: string;
  height?: string;
  background: string;
  zIndex?: string;
  scale?: string;
}

export const BackgroundGlow = (props: BackgroundGlowProps) => {
  return (
    <BackgroundGlowContainer
      width={props.width}
      height={props.height}
      top={props.top}
      bottom={props.bottom}
      left={props.left}
      right={props.right}
      background={props.background}
      zIndex={props.zIndex}
      scale={props.scale}
    ></BackgroundGlowContainer>
  );
};
