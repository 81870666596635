import gsap from "gsap";
const shortid = require("shortid");
import anime from "animejs";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import Image from "next/image";
import {
  ValuePropsGenericCardContainer,
  BannerSection,
  ContentSection,
  LargeText,
  SmallText,
  BackgroundHue,
} from "./styles";

import { useIsomorphicLayoutEffect } from "../../../pages";
import { LandingPageTextAnimation } from "../../common/landing-pages-text-animation";
import { useState } from "react";

interface ValuePropsGenericCardProps {
  containerClassName: string;
  mobileBackgroundImageUrl?: string;
  desktopBackgroundImageUrl?: string;
  largeText: string;
  smallText: string[];
  textAnimationClassName: string;
  shouldPin: boolean;
  backgroundHue?: string;
  huePosition?: string;
  hueWidth?: string;
  hueHeight?: string;
  id?: string;
  altText?: string;
}

/* To avoid GSAP animation conflicts when using the same value props component 
multiple times on the same page, we must provide unique text animation class 
names for each instance of the component. This ensures that the animations do not interfere with each other. */

export const ValuePropsGenericCard = (props: ValuePropsGenericCardProps) => {
  const [showText, setShowText] = useState(false);
  useIsomorphicLayoutEffect(() => {
    const matchMedia = gsap.matchMedia();

    function handleMedia(mediaContext: any) {
      const { isDesktop, isMobile } = mediaContext.conditions;
      if (isMobile) {
        ScrollTrigger.create({
          trigger: `.${props.containerClassName}`,
          start: "top bottom",
          end: `+50%`,
          invalidateOnRefresh: true,
          onEnter: () => {
            setShowText(true);
            anime.timeline({ loop: false }).add({
              targets: `.${props.textAnimationClassName}`,
              translateX: [40, 0],
              translateZ: 0,
              opacity: [0, 1],
              easing: "easeOutExpo",
              duration: 600,
              markers: true,
              delay: (el, i) => 350 + 10 * i,
            });
          },
        });
      } else {
        matchMedia.revert();
      }
    }
    matchMedia.add(
      {
        isMobile: `(max-width: 1060px)`,
        isDesktop: `(min-width: 1060px)`,
      },
      handleMedia
    );

    return () => {
      matchMedia.revert();
    };
  }, []);

  return (
    <ValuePropsGenericCardContainer
      className={props.containerClassName}
      id={props.id}
    >
      <BackgroundHue
        background={props.backgroundHue}
        position={props.huePosition}
        width={props.hueWidth}
        height={props.hueHeight}
      />
      <BannerSection>
        <Image
          fill={true}
          loading="lazy"
          src={props.mobileBackgroundImageUrl || ""}
          style={{ objectFit: "cover", borderRadius: "0px 0px 24px 24px " }}
          alt={props.altText || ""}
        />
        <ContentSection className="value-props-generic-card-content-section">
          {showText && (
            <LandingPageTextAnimation
              firstLine={props.largeText}
              secondLine={props.smallText[0] || ""}
              thirdLine={props.smallText[1] || ""}
              textAnimationClassName={props.textAnimationClassName}
              textContainerClassName={`${props.textAnimationClassName}-container`}
              margin="4px 0px"
              idMargin="6vh 0vh 0vh 0vh"
            />
          )}
        </ContentSection>
      </BannerSection>
    </ValuePropsGenericCardContainer>
  );
};
