import styled from "styled-components";

interface BannerProps {
  background?: any;
}

export const ValuePropsGenericCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  @media (max-width: 1060px) {
    width: 100vw;
    height: 100vh;
  }

  .animation-span {
    display: inline-block;
  }
`;

export const BackgroundHue = styled.div<any>`
  position: absolute;
  width: ${(props) => props.width || "619px"};
  height: ${(props) => props.height || "651px"};
  bottom: ${(props) => props.bottom || "40px"};
  left: ${(props) => props.position};
  background: ${(props) =>
    props.background ||
    `radial-gradient(
    50% 50% at 50% 50%,
    rgba(249, 61, 102, 0.5) 0%,
    rgba(0, 0, 0, 0) 100%
  )`};
  z-index: 0;
`;

export const BannerSection = styled.div<BannerProps>`
  display: none;
  @media (max-width: 1060px) {
    display: block;
    width: 100%;
    height: 70vh;
    /* background-image: ${(props) => `url(${props.background})`};
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; */
    border-radius: 0px 0px 24px 24px;
    position: relative;
  }
`;

export const ContentSection = styled.div`
  position: absolute;
  top: 66vh;
  left: 18px;
  width: calc(100vw - 32px);
  @media screen and (min-width: 520px) and (max-width: 1060px) {
    top: 67vh;
    left: 5vw;
  }
`;

export const LargeText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 46px;
  line-height: 52px;
  letter-spacing: 0.03em;
  color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  @media screen and (min-width: 520px) and (max-width: 1060px) {
    font-size: 60px;
    line-height: 66px;
  }
`;

export const SmallText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 34px;
  letter-spacing: 0.03em;
  color: #ffffff;
  @media screen and (min-width: 520px) and (max-width: 1060px) {
    font-size: 34px;
    line-height: 43px;
  }
`;
