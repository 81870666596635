import styled from "styled-components";

interface BackgroundGlowProps {
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
  width?: string;
  height?: string;
  background: string;
  zIndex?: string;
  scale?: string;
}

export const BackgroundGlowContainer = styled.div<BackgroundGlowProps>`
  position: absolute;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  bottom: ${(props) => props.bottom};
  background: ${(props) => props.background};
  z-index: ${(props) => props.zIndex || -1};
  scale: ${(props) => props.scale || 1};
`;
