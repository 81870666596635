import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { gsap } from "gsap";
const shortid = require("shortid");
import {
  CardHeadingTile,
  CategoryLayerContainer,
  ContentSectionContainer,
  LockscreenCard,
  LockscreenCardsContainer,
  MobileView,
  Ribbon,
  RibbonContainer,
  TabletView,
  TimeDate,
  TimeDateSection,
  WidgetContainer,
} from "./styles";
import { useIsomorphicLayoutEffect } from "../../../pages";
import { LeftWidgetContainer, RightWidgetContainer } from "./widgets/styles";
import Image from "next/image";
import { CategoriesTablet } from "../categories-tablet";
import { useRouter } from "next/router";
gsap.registerPlugin(ScrollTrigger);

interface LockscreenCardsProps {
  data: CategoryData[];
}

export interface CategoryData {
  mobileBackground: string;
  desktopBackground: string;
  desktopLsImage: string;
  leftWidgetUrl: string;
  rightWidgetUrl: string;
  title: string;
  mobileLsImageAltText: string;
}

/* The animation logic in this component is identical to the one used in the Desktop Category section.
 To grasp the GSAP animations applied here, please consult the corresponding component, 
 as both sections utilize the same animation techniques.*/

export const LockscreenCards = (props: LockscreenCardsProps) => {
  const router = useRouter();
  let i = 0;
  let flag = "clock-face-front";
  let flag2 = "clock-face-front-right";

  useIsomorphicLayoutEffect(() => {
    const matchMedia = gsap.matchMedia();

    const handleMedia = (mediaContext: any) => {
      const { isDesktop, isMobile, isTablet } = mediaContext.conditions;
      if (isMobile) {
        let timeline: any;
        let activeIndex = i;
        i++;
        let testFlag = flag;
        if (
          router.locale !== "id" &&
          router.locale !== "id-ID" &&
          router.locale !== "ja-JP" &&
          router.locale !== "jp"
        ) {
          startTime(
            testFlag,
            2,
            props.data[0].leftWidgetUrl,
            0,
            activeIndex + 1
          );
          startTimeRight(
            flag2,
            2,
            props.data[0].rightWidgetUrl,
            0,
            activeIndex + 1
          );
        }

        let ribbons: any[] = gsap.utils.toArray(".ribbon-img");

        for (let i = 1; i < ribbons.length; i++) {
          gsap.set(ribbons[i], {
            opacity: 0,
            transform: "translateX(-20vw)",
            duration: 0,
          });
        }

        timeline = gsap.timeline({
          paused: true,
        });

        ScrollTrigger.create({
          trigger: ".bg-cards-container",
          start: "top 1px",
          end: `+=${window.innerHeight * (props.data.length - 1)}px`,
          pin: true,
          pinSpacing: true,
          scrub: true,
          invalidateOnRefresh: true,
          animation: timeline,
        });

        timeline.to(`.ls-card-category-0`, {
          duration: 1,
          onStart: () => {},
        });

        for (let j = 0; j < props.data.length; j++) {
          if (j !== props.data.length - 1) {
            timeline.to(`.ls-card-category-${j}`, {
              zIndex: -1,
              duration: 1,
              onStart: () => {
                let idx = j;
                let activeIndex = i;
                i++;
                let testFlag = flag;
                if (
                  router.locale !== "id" &&
                  router.locale !== "id-ID" &&
                  router.locale !== "ja-JP" &&
                  router.locale !== "jp"
                ) {
                  startTime(
                    testFlag,
                    2,
                    props.data[idx + 1].leftWidgetUrl,
                    idx + 1,
                    activeIndex + 1
                  );
                  startTimeRight(
                    flag2,
                    2,
                    props.data[idx + 1].rightWidgetUrl,
                    idx + 1,
                    activeIndex + 1
                  );
                } else {
                  gsap.to(ribbons[idx], {
                    opacity: 0,
                    duration: 0.5,
                  });
                  gsap.to(ribbons[idx], {
                    transform: "translateX(-20vw)",
                    duration: 0,
                  });
                  gsap.to(ribbons[idx + 1], {
                    opacity: 1,
                    transform: "translateX(0vw)",
                    duration: 0.5,
                  });
                }
              },
              onReverseComplete: () => {
                let testFlag = flag;
                let idx = j;
                let activeIndex = i;
                i++;
                if (
                  router.locale !== "id" &&
                  router.locale !== "id-ID" &&
                  router.locale !== "ja-JP" &&
                  router.locale !== "jp"
                ) {
                  startTime(
                    testFlag,
                    2,
                    props.data[idx].leftWidgetUrl,
                    idx,
                    activeIndex + 1
                  );
                  startTimeRight(
                    flag2,
                    2,
                    props.data[idx].rightWidgetUrl,
                    idx,
                    activeIndex + 1
                  );
                } else {
                  gsap.to(ribbons[idx + 1], {
                    opacity: 0,
                    transform: "translateX(-10vw)",
                    duration: 0.5,
                  });
                  gsap.to(ribbons[idx], {
                    opacity: 1,
                    transform: "translateX(0vw)",
                    duration: 0.5,
                  });
                }
              },
            });
          }
        }
      } else if (isTablet) {
        ScrollTrigger.create({
          trigger: ".bg-cards-container",
          start: "top 1px",
          end: `+=${window.innerHeight * (props.data.length - 1)}px`,
          pin: true,
          pinSpacing: true,
          scrub: true,
          invalidateOnRefresh: true,
        });
      } else {
        matchMedia.revert();
      }
    };

    matchMedia.add(
      {
        isMobile: `(max-width: 520px)`,
        isTablet: `(max-width: 1060px)`,
        isDesktop: `(min-width: 1060px)`,
      },
      handleMedia
    );

    return () => {
      matchMedia.revert();
    };
  }, []);

  function startTime(
    secFace: any,
    lastSec: any,
    bgUrl: any,
    index: any,
    rotationIdx: any
  ) {
    var today = new Date();
    var s = checkTime(today.getSeconds());
    let prevData: any = "";
    let test = gsap.utils.selector(`#clock-seconds`);
    let test2 = test(`.${secFace}`);
    prevData = test2[0].getAttribute("data-prev");
    let tes = test(`.${prevData}`);

    tes[0].setAttribute("src", bgUrl);
    tes[0].style.objectFit = "cover";
    // tes[0].style.backgroundSize = "cover";
    // tes[0].style.backgroundPosition = "center";
    // tes[0].style.backgroundRepeat = "no-repeat";
    gsap.to("#clock-seconds", {
      rotationX: `+${rotationIdx * 90}`,
      duration: 0.5,
    });
    flag = prevData;
  }

  function startTimeRight(
    secFace: any,
    lastSec: any,
    bgUrl: any,
    index: number,
    rotationIdx: any
  ) {
    var today = new Date();
    var s = checkTime(today.getSeconds());
    let prevData: any = "";
    let test = gsap.utils.selector(`#clock-seconds-right`);
    let test2 = test(`.${secFace}`);
    prevData = test2[0].getAttribute("data-prev");
    let tes = test(`.${prevData}`);

    tes[0].setAttribute("src", bgUrl);
    tes[0].style.objectFit = "cover";
    gsap.to("#clock-seconds-right", {
      rotationX: `+${rotationIdx * 90}`,
      duration: 0.5,
    });
    flag2 = prevData;
  }

  function checkTime(i: any) {
    if (i < 10) return "0" + i;
    return i;
  }

  const generateLockscreenCards = () => {
    return props.data.map((card, index) => {
      return (
        <LockscreenCard
          key={shortid.generate()}
          className={`ls-card-category-${index} lockscreen-card`}
          zIndex={props.data.length - index}
        >
          <Image
            fill={true}
            loading="lazy"
            src={card.mobileBackground}
            style={{
              objectFit: "cover",
            }}
            quality={100}
            alt={card.mobileLsImageAltText}
          />
        </LockscreenCard>
      );
    });
  };

  const usDays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const usMonths = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
  const months = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];

  const timeDate = () => {
    let date = new Date();
    let hours: any = date.getHours();
    let day = date.getDay();
    let month = date.getMonth();
    let exactDate = date.getDate();
    let dateToBeShown = "";
    let timeTobeShown = "";
    let minutes =
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    let isAM = hours < 12;
    if (router.locale === "us") {
      if (hours > 12) {
        hours -= 12;
      } else if (hours === 0) {
        hours = 12;
      }
      hours = hours < 10 ? "0" + hours : hours;

      if (date.getHours() > 12) {
        isAM = false;
      }

      dateToBeShown = usDays[day] + ", " + usMonths[month] + " " + exactDate;
      timeTobeShown = hours + ":" + minutes;
    } else {
      hours = hours < 10 ? "0" + hours : hours;
      dateToBeShown = days[day] + ", " + exactDate + " " + months[month];
      timeTobeShown = hours + ":" + minutes;
    }

    return (
      <>
        <div className="time">{timeTobeShown}</div>
        <div className="date">{dateToBeShown}</div>
      </>
    );
  };

  const generateIDRibbons = () => {
    const idRibbons = [
      "https://web.glance-cdn.com/images/glance-website/corp/id/lockscreen/ribbon/cat1-ribbon.png",
      "https://web.glance-cdn.com/images/glance-website/corp/id/lockscreen/ribbon/cat2-ribbon.png",
      "https://web.glance-cdn.com/images/glance-website/corp/id/lockscreen/ribbon/cat3-ribbon.png",
      "https://web.glance-cdn.com/images/glance-website/corp/id/lockscreen/ribbon/cat4-ribbon.png",
    ];

    return props.data.map((url: CategoryData, index: number) => {
      return (
        <Ribbon
          className="ribbon-img"
          loading="lazy"
          src={url.leftWidgetUrl}
          key={index}
        />
      );
    });
  };

  return (
    <div style={{ position: "relative" }}>
      <CategoryLayerContainer className="bg-cards-container" id="fourth">
        <MobileView>
          {generateLockscreenCards()}
          <LockscreenCardsContainer className="ls-card-categorys-container">
            <TimeDate>
              <img className="lock-img" src="/lock.png"></img>
              <div className="time-detail">{timeDate()}</div>
            </TimeDate>

            {router.locale !== "id" &&
            router.locale !== "id-ID" &&
            router.locale !== "ja-JP" &&
            router.locale !== "jp" ? (
              <WidgetContainer className="widget-container-test">
                <LeftWidgetContainer>
                  <div id="content-container-header-clock">
                    <div id="clock-seconds">
                      <Image
                        src=""
                        alt="cube"
                        fill={true}
                        loading="lazy"
                        className="clock-face clock-face-front"
                        data-next="clock-face-top"
                        data-prev="clock-face-bottom"
                      ></Image>
                      <Image
                        src=""
                        alt="cube"
                        fill={true}
                        loading="lazy"
                        className="clock-face clock-face-bottom"
                        data-next="clock-face-front"
                        data-prev="clock-face-back"
                      ></Image>
                      <Image
                        src=""
                        alt="cube"
                        fill={true}
                        loading="lazy"
                        className="clock-face clock-face-left"
                      ></Image>
                      <Image
                        src=""
                        alt="cube"
                        fill={true}
                        loading="lazy"
                        className="clock-face clock-face-right"
                      ></Image>
                      <Image
                        src=""
                        alt="cube"
                        fill={true}
                        loading="lazy"
                        className="clock-face clock-face-back"
                        data-next="clock-face-bottom"
                        data-prev="clock-face-top"
                      ></Image>
                      <Image
                        src=""
                        alt="cube"
                        fill={true}
                        loading="lazy"
                        className="clock-face clock-face-top"
                        data-next="clock-face-back"
                        data-prev="clock-face-front"
                      ></Image>
                    </div>
                  </div>
                </LeftWidgetContainer>
                <RightWidgetContainer>
                  <div id="content-container-header-clock-right">
                    <div id="clock-seconds-right">
                      <Image
                        src=""
                        alt="cube"
                        fill={true}
                        loading="lazy"
                        className="clock-face-right clock-face-front-right"
                        data-next="clock-face-top-right"
                        data-prev="clock-face-bottom-right"
                      ></Image>
                      <Image
                        src=""
                        alt="cube"
                        fill={true}
                        loading="lazy"
                        className="clock-face-right clock-face-bottom-right"
                        data-next="clock-face-front-right"
                        data-prev="clock-face-back-right"
                      ></Image>
                      <Image
                        src=""
                        alt="cube"
                        fill={true}
                        loading="lazy"
                        className="clock-face-right clock-face-left-right"
                      ></Image>
                      <Image
                        src=""
                        alt="cube"
                        fill={true}
                        loading="lazy"
                        className="clock-face-right clock-face-right-right"
                      ></Image>
                      <Image
                        src=""
                        alt="cube"
                        fill={true}
                        loading="lazy"
                        className="clock-face-right clock-face-back-right"
                        data-next="clock-face-bottom-right"
                        data-prev="clock-face-top-right"
                      ></Image>
                      <Image
                        src=""
                        alt="cube"
                        fill={true}
                        loading="lazy"
                        className="clock-face-right clock-face-top-right"
                        data-next="clock-face-back-right"
                        data-prev="clock-face-front-right"
                      ></Image>
                    </div>
                  </div>
                </RightWidgetContainer>
              </WidgetContainer>
            ) : (
              <RibbonContainer>{generateIDRibbons()}</RibbonContainer>
            )}
          </LockscreenCardsContainer>
        </MobileView>
      </CategoryLayerContainer>
      <TabletView>
        <CategoriesTablet data={props.data} />
      </TabletView>
    </div>
  );
};
