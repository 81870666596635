import styled from "styled-components";

export const LeftWidgetContainer = styled.div<any>`
  width: 76px;
  height: 76px;
  #content-container-header-clock {
    height: 100%;
    width: 76px;
    float: right;
    padding: 10px;
    perspective: 200;
    -webkit-perspective: 200;
    color: white;
  }

  #clock-seconds {
    width: 76px;
    height: 76px;
    float: left;
    transform-style: preserve-3d;
  }

  .clock-face {
    z-index: 100;
    position: absolute;
    width: 76px;
    height: 76px;
    border-radius: 12px;
    /* background: url("https://web.glance-cdn.com/images/corp/lockscreen/games-widget-left.svg"); */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    line-height: 32px;
    text-align: center;
    -webkit-backface-visibility: hidden; /* Chrome, Safari, Opera */
    backface-visibility: hidden;
  }

  .clock-face-front {
    transform: translate3d(0, 0, 38px);
  }

  .clock-face-back {
    transform: rotate3d(0, 1, 0, 180deg) rotate3d(0, 0, 1, 180deg)
      translate3d(0, 0, 38px);
  }

  .clock-face-bottom {
    transform: rotate3d(1, 0, 0, -90deg) translate3d(0, 0, 38px);
  }

  .clock-face-top {
    transform: rotate3d(1, 0, 0, 90deg) translate3d(0, 0, 38px);
  }

  .clock-face-right {
    background-color: rgba(0, 0, 0, 0.9);
    transform: rotate3d(0, 1, 0, 90deg) translate3d(0, 0, 38px);
  }

  .clock-face-left {
    background-color: rgba(0, 0, 0, 0.9);
    transform: rotate3d(0, 1, 0, -90deg) translate3d(0, 0, 38px);
  }
`;

export const RightWidgetContainer = styled.div<any>`
  width: 45vw;
  height: 76px;
  #content-container-header-clock-right {
    height: 100%;
    width: 45vw;
    float: right;
    padding: 10px;
    perspective: 200;
    -webkit-perspective: 200;
    color: white;
  }

  #clock-seconds-right {
    width: 45vw;
    height: 76px;
    float: left;
    transform-style: preserve-3d;
  }

  .clock-face-right {
    z-index: 100;
    position: absolute;
    width: 45vw;
    height: 76px;
    border-radius: 12px;
    /* background: url("https://web.glance-cdn.com/images/corp/lockscreen/games-widget-left.svg"); */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    line-height: 32px;
    text-align: center;
    -webkit-backface-visibility: hidden; /* Chrome, Safari, Opera */
    backface-visibility: hidden;
    @media (min-width: 440px) and (max-width: 520px) {
      width: 36vw;
    }
  }

  .clock-face-front-right {
    transform: translate3d(0, 0, 38px);
  }

  .clock-face-back-right {
    transform: rotate3d(0, 1, 0, 180deg) rotate3d(0, 0, 1, 180deg)
      translate3d(0, 0, 38px);
  }

  .clock-face-bottom-right {
    transform: rotate3d(1, 0, 0, -90deg) translate3d(0, 0, 38px);
  }

  .clock-face-top-right {
    transform: rotate3d(1, 0, 0, 90deg) translate3d(0, 0, 38px);
  }

  .clock-face-right-right {
    background-color: rgba(0, 0, 0, 0.9);
    transform: rotate3d(0, 1, 0, 90deg) translate3d(0, 0, 38px);
  }

  .clock-face-left-right {
    background-color: rgba(0, 0, 0, 0.9);
    transform: rotate3d(0, 1, 0, -90deg) translate3d(0, 0, 38px);
  }
`;
