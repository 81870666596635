import { useEffect, useRef, useState } from "react";
import gsap from "gsap";
const shortid = require("shortid");
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import anime from "animejs";
import {
  ContentSection,
  Dot,
  DotsContainer,
  HogCard,
  HogCardContainer,
  HogContainer,
} from "./styles";
import { useTranslation } from "next-i18next";
import { useIsomorphicLayoutEffect } from "../../../pages";
import { LandingPageTextAnimation } from "../../common/landing-pages-text-animation";
gsap.registerPlugin(ScrollTrigger);
import { TagManagerEvent, event } from "../../../utils/gtag";
import Image from "next/image";

interface HogProps {
  hogData: any[];
  id?: string;
}

export const HumansOfGlance = (props: HogProps) => {
  let [currentIndex, setCurrentIndex] = useState(0);
  const [intervalId, setIntervalId] = useState<any>(null);
  const [openPopup, setOpenPopup] = useState(false);
  const { t } = useTranslation("common");
  const textAnimeRef = useRef<any>(null);
  let touchStartX = 0;
  let touchEndX = 0;
  const myComponentRef = useRef(null);
  const videoRef: any = useRef(null);

  useIsomorphicLayoutEffect(() => {
    let matchMedia = gsap.matchMedia();

    function handleMedia(mediaContext: any) {
      const { isDesktop, isMobile } = mediaContext.conditions;
      if (isMobile) {
        let timeline: any;
        let timelineTwo: any;
        let scrollTriggerOne: any;
        let scrollTriggerTwo: any;
        timelineTwo = gsap.timeline({
          paused: true,
        });

        timeline = gsap.timeline({
          paused: true,
        });

        scrollTriggerOne = ScrollTrigger.create({
          trigger: ".hog-page-container",
          start: "top 1px",
          end: `+=100%`,
          pin: false,
          invalidateOnRefresh: true,
          animation: timeline,
        });

        scrollTriggerTwo = ScrollTrigger.create({
          trigger: ".hog-page-container",
          start: "top center",
          end: `+=50%`,
          invalidateOnRefresh: true,
          animation: timelineTwo,
          onEnter: () => {
            textAnimeRef.current = anime.timeline({ loop: false }).add({
              targets: ".letter-itn",
              translateX: [40, 0],
              translateZ: 0,
              opacity: [0, 1],
              easing: "easeOutExpo",
              duration: 600,
              markers: true,
              delay: (el, i) => 350 + 10 * i,
            });
          },
        });
      } else {
        matchMedia.revert();
      }
    }

    matchMedia.add(
      {
        isMobile: `(max-width: 1060px)`,
        isDesktop: `(min-width: 1060px)`,
      },
      handleMedia
    );

    return () => {
      matchMedia.revert();
    };
  }, []);

  useEffect(() => {
    let timeline = gsap.timeline({});
    timeline
      .to(".itn-tdssa", {
        opacity: 0,
        duration: 0,
      })
      .to(".itn-tdssa", {
        opacity: 1,
        duration: 1,
      })
      .to(".user-details", {
        opacity: 0,
        duration: 0,
        delay: -1,
      })
      .to(".user-details", {
        opacity: 1,
        duration: 1,
        delay: -1,
      })
      .to(".hog-title", {
        opacity: 0,
        duration: 0,
        delay: -1,
      })
      .to(".hog-title", {
        opacity: 1,
        duration: 1,
        delay: -1,
      });

    for (let i = 0; i < props.hogData.length; i++) {
      gsap.to(`.product-page-hogData-card-${i}`, {
        opacity: 0,
        zIndex: 1,
        duration: 1,
      });
    }
    gsap.to(`.product-page-hogData-card-${currentIndex}`, {
      opacity: 1,
      zIndex: 1,
      duration: 1,
    });
  }, [currentIndex]);

  useEffect(() => {
    let id: any = "";
    if (openPopup) {
      if (intervalId) {
        clearInterval(intervalId);
        setIntervalId(null);
      }
    } else {
      id = setInterval(() => {
        setCurrentIndex((prevIndex) => {
          return (prevIndex + 1) % props.hogData.length;
        });
      }, 4000);
      setIntervalId(id);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
        setIntervalId(null);
      }
    };
  }, [openPopup]);

  useEffect(() => {
    const myComponentElement: any = myComponentRef.current;

    myComponentElement.addEventListener("touchstart", handleTouchStart, false);
    myComponentElement.addEventListener("touchend", handleTouchEnd, false);

    return () => {
      myComponentElement.removeEventListener("touchstart", handleTouchStart);
      myComponentElement.removeEventListener("touchend", handleTouchEnd);
    };
  }, []);

  function getNearestStandardPercentage(videoPercentage: any) {
    const standardPercentages = [0, 25, 50, 75, 100];
    let nearest = standardPercentages[0];
    let smallestDifference = Math.abs(videoPercentage - nearest);

    for (let i = 1; i < standardPercentages.length; i++) {
      const difference = Math.abs(videoPercentage - standardPercentages[i]);
      if (difference < smallestDifference) {
        smallestDifference = difference;
        nearest = standardPercentages[i];
      }
    }

    return nearest;
  }

  const handleTouchStart = (e: any) => {
    touchStartX = e.changedTouches[0].screenX;
  };

  const handleTouchEnd = (e: any) => {
    touchEndX = e.changedTouches[0].screenX;
    handleSwipeGesture();
  };

  const handleSwipeGesture = () => {
    if (touchEndX < touchStartX) {
      setCurrentIndex((prevIndex) => {
        if (prevIndex < props.hogData.length - 1) {
          return prevIndex + 1;
        }
        return prevIndex;
      });
    }

    if (touchEndX > touchStartX) {
      setCurrentIndex((prevIndex) => {
        if (prevIndex > 0) {
          return prevIndex - 1;
        }
        return prevIndex;
      });
    }
  };

  const observerRef = useRef(null);
  useEffect (() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          console.log("HOG MOBILE")
          observer.unobserve(entry.target);
          const event = {
            event: "video",
            video_status: "in_view",
            video_provider: "html player-HOG",
            video_percent: 0
          };
          TagManagerEvent(event);
        }
      });
    });

    if(observerRef.current){
      observer.observe(observerRef.current);
    }
  },[])

  const generateHogCards = () => {
    return props.hogData?.map((item, index) => {
      return (
        <div key={index} ref={observerRef}>
          <HogCard
            zIndex={props.hogData.length - index}
            className={`product-page-hogData-card-${index}`}
            onClick={() => {
              setOpenPopup(true);
              const event = {
                event: "video",
                video_status: "start",
                video_provider: "html player-HOG",
                video_percent: 0,
                video_title: `HOG: ${props.hogData[currentIndex].name}`,
              };
              TagManagerEvent(event);
            }}
          >
            <Image
              src={item.mobileBackground.data?.attributes.url}
              alt={"hog"}
              fill={true}
              style={{ objectFit: "cover" }}
            ></Image>
          </HogCard>
        </div>
      );
    });
  };

  return (
    <HogContainer className="hog-page-container" id={props.id}>
      <HogCardContainer className="hog-page-card-wrapper" ref={myComponentRef}>
        {generateHogCards()}

        <div
          className="svg-container"
          onClick={() => {
            setOpenPopup(true);
            const event = {
              event: "video",
              video_status: "start",
              video_provider: "html player-HOG",
              video_percent: 0,
              video_title: `HOG: ${props.hogData[currentIndex].name}`,
            };
            TagManagerEvent(event);
          }}
        >
          <svg
            width="71"
            height="71"
            viewBox="0 0 71 71"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle opacity="0.6" cx="35.5" cy="35.5" r="35.5" fill="#D9D9D9" />
            <path
              d="M55 35.5L25.75 52.3875L25.75 18.6125L55 35.5Z"
              fill="white"
            />
          </svg>
        </div>
      </HogCardContainer>
      <ContentSection>
        <LandingPageTextAnimation
          firstLine={props.hogData[currentIndex].firstLine}
          secondLine={props.hogData[currentIndex].secondLine}
          textAnimationClassName={"itn-tdssa"}
          textContainerClassName={"itssn-ta-container"}
          margin="0px"
          idMargin="0px"
        />
        <div className="user-details">
          <div className="name">{props.hogData[currentIndex].name}</div>
          <div>|</div>
          <div className="location">{props.hogData[currentIndex].location}</div>
        </div>
        <div className="hog-title">
          <i>#HumansOfGlance</i>
        </div>
      </ContentSection>
      <div className="bottom-wrapper">
        <DotsContainer>
          {props.hogData.map((item, idx) => {
            return (
              <Dot
                key={shortid.generate()}
                background={
                  currentIndex === idx ? "white" : "rgba(217, 217, 217, 0.3);"
                }
              ></Dot>
            );
          })}
        </DotsContainer>
      </div>
      {openPopup && (
        <div className="popup-container">
          <div
            className="cancel-icon"
            onClick={() => {
              let completePercentage;
              if (videoRef.current) {
                let currentTime = videoRef.current?.currentTime;
                completePercentage = Math.floor(
                  (currentTime / videoRef.current?.duration) * 100
                );
              }
              const event = {
                event: "video",
                video_status: "complete",
                video_provider: "html player-HOG",
                video_percent: getNearestStandardPercentage(completePercentage),
                video_title: `HOG: ${props.hogData[currentIndex].name}`,
              };
              TagManagerEvent(event);
              setOpenPopup(false);
            }}
          >
            <img src="https://i-l-inmobicdn-net.azureedge.net/website/website/6.0.1/img/close_nav.png" />
          </div>
          <video
            ref={videoRef}
            src={props.hogData[currentIndex].videoUrl}
            autoPlay
            loop
            muted
            playsInline
            controls
          />
        </div>
      )}
    </HogContainer>
  );
};
