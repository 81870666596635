import anime from "animejs";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
const shortid = require("shortid");
import { useIsomorphicLayoutEffect } from "../../../pages";
import { BackgroundGlow } from "../../common/background-glow";
import {
  CountriesCard,
  CountriesCardWrapper,
  CountriesContainer,
  CountryImg,
  Smalltext,
  TextContent,
} from "./styles";
import Image from "next/image";
import { LandingPageTextAnimation } from "../../common/landing-pages-text-animation";
import { useRouter } from "next/router";
import { DynamicLargeText } from "../../homescreen/common";
import { textAnimationCSSMapper } from "../categories-desktop";
gsap.registerPlugin(ScrollTrigger);

interface CountriesProps {
  data: CountryData[];
  shouldPin: boolean;
  pinStart: string;
  secondLine: string;
  thirdLine: string;
}

interface CountryData {
  title: string;
  mobileBackground: string;
  desktopBackground: string;
  desktopLsImage: string;
  mobileLsImageAltText: string;
}

const countries = ["India", "Indonesia", "Brazil", "Colombia", "Mexico"];
const imgUrls = [
  "https://web.glance-cdn.com/images/glance-website/corp/lockscreen/countries/india.webp",
  "https://web.glance-cdn.com/images/glance-website/corp/lockscreen/countries/indonesia.webp",
  "https://web.glance-cdn.com/images/glance-website/corp/lockscreen/countries/brazil.webp",
  "https://web.glance-cdn.com/images/glance-website/corp/lockscreen/countries/columbia.webp",
  "https://web.glance-cdn.com/images/glance-website/corp/lockscreen/countries/mexico.webp",
];

export const textCss: any = {
  enBold: `position: absolute;
  bottom: -18px;
  left: 22px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 46px;
  line-height: 35px;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #ffffff;
  @media screen and (min-width: 520px) and (max-width: 1060px) {
    font-size: 60px;
    line-height: 66px;
  }`,
  idBold: `
  position: absolute;
  bottom: -18px;
  left: 22px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 25px;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #ffffff;
  @media screen and (min-width: 520px) and (max-width: 1060px) {
    font-size: 50px;
    line-height: 56px;
  }
  `,
  jpBold: `
    position: absolute;
  left: 22px;
  font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: 1px;
    color: #ffffff;
    @media screen and (min-width: 1060px) and (max-width: 1260px) {
      font-size: 36px;
      line-height: 44px;
    }

    @media screen and (min-width: 520px) and (max-width: 992px) {
      font-size: 32px;
      line-height: 40px;
    }

    @media screen and (min-width: 380px) and (max-width: 520px) {
      font-size: 28px;
      line-height: 36px;
    }

    @media screen and (min-width: 280px) and (max-width: 380px) {
      font-size: 26px;
      line-height: 34px;
    }
  `,
};

export const Countries = (props: CountriesProps) => {
  const router: any = useRouter();

  useIsomorphicLayoutEffect(() => {
    let matchMedia = gsap.matchMedia();

    function handleMedia(mediaContext: any) {
      const { isMobile } = mediaContext.conditions;
      if (isMobile) {
        let timeline: any;
        let scrollTrigger: any;
        let arr: any[] = gsap.utils.toArray(".countries-card");
        gsap.set(arr[0], {
          opacity: 1,
        });

        ScrollTrigger.create({
          trigger: ".countries-container",
          start: "top center",
          end: "bottom center",
          onEnter: () => {
            anime.timeline({ loop: false }).add({
              targets: ".letter-coun",
              translateX: [40, 0],
              translateZ: 0,
              opacity: [0, 1],
              easing: "easeOutExpo",
              duration: 600,
              delay: (el, i) => 350 + 10 * i,
            });
          },
        });

        /* repeat:-1 will help us to restart the animation once it reaches to end */

        timeline = gsap.timeline({ paused: true, repeat: -1 });
        timeline.set(arr[0], {
          opacity: 1,
        });

        /* fade in fadeout animation */
        arr.forEach((el: any, index) => {
          if (index != arr.length - 1) {
            timeline
              .to(arr[index + 1], {
                duration: 1,
              })
              .to(el, {
                opacity: 0,
                duration: 1,
              })
              .to(arr[index + 1], {
                opacity: 1,
                duration: 1,
                delay: -1,
              });
          }
        });

        scrollTrigger = ScrollTrigger.create({
          trigger: ".countries-container",
          start: "top center",
          end: `+=70%`,
          pin: false,
          // pinType: "fixed",
          scrub: false,
          invalidateOnRefresh: true,
          pinSpacing: true,
          animation: timeline,
          toggleActions: "play pause resume pause",
        });
      } else {
        matchMedia.revert();
      }
    }

    matchMedia.add(
      {
        isMobile: `(max-width: 1060px)`,
        isDesktop: `(min-width: 1060px)`,
      },
      handleMedia
    );

    return () => {
      matchMedia.revert();
    };
  }, []);

  const generateCountries = () => {
    return props.data.map((country, index) => {
      return (
        <CountriesCard
          key={shortid.generate()}
          className={`.countries-card-${index} countries-card`}
          background={props.data[index].mobileBackground}
          zIndex={props.data?.length - index}
        >
          <Image
            width={1300}
            height={1400}
            // fill={true}
            // layout="fill"
            loading="lazy"
            src={country.mobileBackground}
            style={{
              objectFit: "cover",
              borderRadius: "0px 0px 32px 32px ",
              width: "100vw",
              height: "70vh",
            }}
            quality={100}
            alt={country.mobileLsImageAltText}
          />
          {router.locale === "id" ? (
            <LandingPageTextAnimation
              firstLine={""}
              secondLine={props.secondLine}
              thirdLine={props.thirdLine}
              textAnimationClassName={`letter-coun`}
              textContainerClassName={`letter-coun-container`}
              margin="0px 16px 0px 22px"
              idMargin="16px 22px"
            />
          ) : (
            ""
          )}
          <DynamicLargeText
            styles={
              textCss[`${textAnimationCSSMapper[router.locale] || "en"}Bold`]
            }
            className="countries-text-content"
            margin={router.locale == "id" ? "-72px 0px" : "0px"}
          >
            {country.title}
          </DynamicLargeText>
        </CountriesCard>
      );
    });
  };

  return (
    <CountriesContainer className="countries-container" id="eleven">
      <BackgroundGlow
        background="radial-gradient(50% 50% at 50% 50%, rgba(160, 29, 146, 0.6) 0%, rgba(0, 0, 0, 0) 100%)"
        height="652px"
        width="740px"
        bottom="14vh"
        left="0px"
        zIndex="0"
      />
      <CountriesCardWrapper>{generateCountries()}</CountriesCardWrapper>

      {router.locale !== "id" ? (
        <LandingPageTextAnimation
          firstLine={""}
          secondLine={props.secondLine}
          thirdLine={props.thirdLine}
          textAnimationClassName={`letter-coun`}
          textContainerClassName={`letter-coun-container`}
          margin="0px 16px 0px 22px"
          idMargin="12px 22px"
        />
      ) : (
        ""
      )}
    </CountriesContainer>
  );
};
