import styled from "styled-components";

export const CategoryLayerContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
  margin-bottom: 16vh;
`;

export const LockscreenCardsContainer = styled.div`
  display: none;

  @media (max-width: 1060px) {
    width: 100vw !important;
    height: 100vh !important;
    scale: 1;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: top;
    margin-bottom: 40px;
    z-index: 10 !important;
    .card-wrapper {
      max-height: 100vh;
      width: 100%;
      position: absolute;
    }
  }
`;

export const LockscreenCard = styled.div<any>`
  display: none;
  display: block;
  @media (max-width: 1060px) {
    position: absolute;
    min-width: 100vw;
    min-height: 100vh;
    /* background-image: ${(props) => `url(${props.background})`};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom; */
    z-index: ${(props) => props.zIndex};
  }
`;

export const ContentSectionContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  .card-wrapper {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }
`;

export const TimeDateSection = styled.img`
  z-index: 3;
  position: relative;
  height: 120px;
  top: 60px;
`;

export const TimeDate = styled.div`
  z-index: 3;
  position: relative;
  justify-content: center;
  display: flex;
  align-items: center;
  color: #fff;
  flex-direction: column;
  /* margin-top: 1vh; */

  .lock-img {
    margin-top: 4rem;
    width: 21px;
    transform: translateY(-6px);
  }
  .time-detail {
    .time {
      font-size: 48px;
      font-family: "Inter";
    }
    .date {
      font-size: 16px;
      font-family: "Inter";
      text-align: center;
    }
  }
`;

export const WidgetContainer = styled.div`
  position: absolute;
  top: 72vh;
  padding: 0px 58px 12px 22px;
  width: 100vw;
  min-height: 130px;
  z-index: 3;
  display: flex;
  justify-content: space-between;
  @media (min-width: 440px) and (max-width: 520px) {
    padding: 0px 44px12px 50px;
  }
`;

export const LeftWidgetContainer = styled.div`
  width: 109px;
  height: 68px;
  position: relative;
  overflow: hidden;
`;

export const LeftWidget = styled.div<any>`
  width: 109px;
  height: 68px;
  border-radius: 12px;
  background: ${(props) => `url(${props.background})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

export const RightWidgetContainer = styled.div`
  width: 215px;
  height: 68px;
  position: relative;
  overflow: hidden;
`;

export const RightWidget = styled.div<any>`
  overflow: hidden;
  width: 215px;
  height: 68px;
  border-radius: 12px;
  background: ${(props) => `url(${props.background})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

export const CardHeadngContainer = styled.div`
  position: absolute;
  z-index: 3;
  top: 56vh;
  height: 180px;
  overflow: hidden;
  padding: 0px 26px;
`;

export const CardHeadingTile = styled.div`
  display: flex;
  flex-direction: column;
  height: 180px;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 56px;
  text-align: left;
  letter-spacing: 0.04em;
  color: white;
  .tit-span {
    /* opacity: 0; */
  }
`;

export const MobileView = styled.div`
  display: none;
  @media (max-width: 520px) {
    display: block;
  }
`;

export const TabletView = styled.div`
  display: none;
  @media (min-width: 520px) and (max-width: 1060px) {
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
  }
`;

export const RibbonContainer = styled.div`
  width: 90vw;
  height: 100px;
  transform: translateY(50vh);
  position: relative;
  overflow: hidden;
`;

export const Ribbon = styled.img`
  width: 100%;
  position: absolute;
`;
