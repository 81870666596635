import styled from "styled-components";

interface NewsCardProps {
  background?: string;
  zIndex: string | number;
}

export const ProductPageNewsContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100vw;
  height: 100vh;
`;

export const NewsCardContainer = styled.div`
  width: 100vw;
  height: 70vh;
  border-radius: 0px 0px 32px 32px;
  position: relative;
  overflow: hidden;
`;

export const NewsCard = styled.div<NewsCardProps>`
  width: 100vw;
  height: 70vh;
  position: absolute;
  background: ${(props) => `url(${props.background})`};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: ${(props) => props.zIndex};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 15% 5%;
`;

export const PublishDate = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
  @media screen and (min-width: 520px) and (max-width: 1060px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const Description = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  margin: 12px 0px;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  @media screen and (min-width: 520px) and (max-width: 1060px) {
    font-size: 20px;
    line-height: 24px;
  }
  /* text-overflow: ellipsis; */
`;

export const ContentSection = styled.div`
  position: relative;
  top: -36px;
  left: 32px;
  z-index: 99;
  width: calc(100vw - 32px);
`;

export const DotsContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-self: flex-start;
  align-self: flex-end; */
  position: absolute;
  right: 20px;
  top: calc(37.5vh - 5.5px);
`;

export const Dot = styled.div<any>`
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: ${(props) => props.background};
  margin: 4px;
`;

export const LargeText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 46px;
  line-height: 51px;
  letter-spacing: 0.03em;
  color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  @media screen and (min-width: 520px) and (max-width: 1060px) {
    font-size: 60px;
    line-height: 66px;
  }
  .hide-text-product-page-news {
    opacity: 0;
    transform: translateY(100%);
  }
`;
