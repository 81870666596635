import styled from "styled-components";

interface CardProps {
  background?: string;
  zIndex: number;
}

export const CountriesContainer = styled.div`
  width: 100vw;
  height: 100vh;
  margin-bottom: 32px;
  position: relative;
  overflow: hidden;
  .letter-coun {
    display: inline-block;
  }
`;

export const CountriesCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 75vh;
  position: relative;
`;

export const CountriesCard = styled.div<CardProps>`
  position: absolute;
  width: 100vw;
  height: 70vh;
  z-index: ${(props) => props.zIndex};
  border-radius: 0px 0px 32px 32px;
  opacity: 0;
`;

export const CountryImg = styled.img`
  position: absolute;
  object-fit: cover;
  height: 70vh;
  width: 100vw;
  border-radius: 0px 0px 32px 32px;
`;

export const TextContent = styled.div<any>`
  ${(props) => props.styles};
`;

export const Smalltext = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 29px;
  letter-spacing: 0.03em;
  color: #ffffff;
  margin-left: 24px;
  @media screen and (min-width: 520px) and (max-width: 1060px) {
    font-size: 34px;
    line-height: 43px;
  }
`;
