import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { useIsomorphicLayoutEffect } from "../../../pages";
import {
  UserReachContainer,
  BannerSection,
  ContentSection,
  Smalltext,
} from "./styles";
import gsap from "gsap";
import anime from "animejs";
import { BackgroundGlow } from "../../common/background-glow";
import CountUp from "react-countup";
import {
  LandingPageTextAnimation,
  localeToFontClassMap,
} from "../../common/landing-pages-text-animation";
import { DynamicLargeText, LargeText } from "../../homescreen/common";
import { largeTextCss } from "../../../utils/homepageUtils";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
const shortid = require("shortid");

interface UserReachProps {
  data: UserReachData;
}

interface UserReachData {
  mobileBackground: string;
  desktopBackground: string;
  desktopLsImage: string;
  firstLine: number;
  secondLine: string;
  thirdLine: string;
}

gsap.registerPlugin(ScrollTrigger);

export const UserReach = (props: UserReachProps) => {
  const router = useRouter();
  const [showText, setShowText] = useState(false);

  useIsomorphicLayoutEffect(() => {
    const matchMedia = gsap.matchMedia();
    let animationOne: any;
    let animationTwo: any;
    let constext = gsap.context(() => {
      function handleMedia(mediaContext: any) {
        const { isDesktop, isMobile } = mediaContext.conditions;
        if (isMobile) {
          animationOne = gsap.to(`.user-reach-section-container`, {
            scrollTrigger: {
              trigger: ".user-reach-section-container",
              start: "top 30%",
              end: "+=50%",
              onEnter: () => {
                setShowText(true);
                /* Text animation */
                anime.timeline({ loop: false }).add({
                  targets: ".user-reach-ta .letter-ur",
                  translateX: [40, 0],
                  translateZ: 0,
                  opacity: [0, 1],
                  easing: "easeOutExpo",
                  duration: 800,
                  markers: true,
                  delay: (el, i) => 500 + 10 * i,
                });
              },
            },
          });
          animationTwo = gsap.to(`.user-reach-section-container`, {
            scrollTrigger: {
              trigger: ".user-reach-section-container",
              scrub: true,
              start: "top 0px",
              end: "+=300px",
              pinSpacing: true,
              onEnter: () => {
                setShowText(true);
              },
            },
          });
        } else {
          matchMedia.revert();
        }
      }

      matchMedia.add(
        {
          isMobile: `(max-width: 1060px)`,
          isDesktop: `(min-width: 1060px)`,
        },
        handleMedia
      );
    });
    return () => {
      animationOne && animationOne.kill();
      animationTwo && animationTwo.kill();
      matchMedia.revert();
      constext && constext.revert();
    };
  }, []);

  return (
    <UserReachContainer className="user-reach-section-container" id="ten">
      <BannerSection background={props.data.mobileBackground}>
        {showText && (
          <ContentSection
            style={{
              bottom: !props.data.firstLine
                ? router.locale == "id"
                  ? "-80px"
                  : "-60px"
                : "-98px",
            }}
          >
            {props.data.firstLine ? (
              <DynamicLargeText
                styles={
                  largeTextCss[
                    `${localeToFontClassMap[router.locale || ""]}Bold`
                  ]
                }
              >
                {/* We are using Countup library to achieve counter animation. 
            This animation happens only when component is in viewport (enableScrollPy property helps with this).
            */}
                <CountUp
                  start={80000}
                  end={props.data.firstLine}
                  duration={1}
                  enableScrollSpy
                >
                  {({ countUpRef, start }) => (
                    <div>
                      <span ref={countUpRef} />
                    </div>
                  )}
                </CountUp>
              </DynamicLargeText>
            ) : (
              <></>
            )}

            <LandingPageTextAnimation
              firstLine={""}
              secondLine={props.data.secondLine}
              thirdLine={props.data.thirdLine}
              textAnimationClassName={`user-reach-ta`}
              textContainerClassName={`user-reach-ta-container`}
              margin="6px 0px 0px 0px"
              idMargin="0vh"
            />
          </ContentSection>
        )}
      </BannerSection>
      <BackgroundGlow
        background="radial-gradient(50% 50% at 50% 50%, rgba(160, 29, 146, 0.6) 0%, rgba(0, 0, 0, 0) 100%)"
        height="652px"
        width="740px"
        bottom="0vh"
        left="0px"
        zIndex="0"
      />
    </UserReachContainer>
  );
};
