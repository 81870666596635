import styled from "styled-components";

interface BannerProps {
  background?: any;
}

export const UserReachContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  @media (max-width: 520px) {
    width: 100vw;
    height: 100vh;
  }
  @media screen and (min-width: 520px) and (max-width: 1060px) {
    width: 100vw;
    height: 100vh;
  }
  .letter-ur {
    display: inline-block;
  }
`;

export const BannerSection = styled.div<BannerProps>`
  width: 100%;
  height: 70vh;
  background: ${(props) => `url(${props.background})`};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 0px 0px 24px 24px;
  position: relative;
  z-index: 1;
`;

export const ContentSection = styled.div`
  position: absolute;
  bottom: -99px;
  left: 12px;
  @media screen and (min-width: 520px) and (max-width: 1060px) {
    bottom: -136px;
    left: 4vh;
  }
`;

export const LargeText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 46px;
  line-height: 46px;
  letter-spacing: 0.03em;
  color: #ffffff;
  margin-bottom: 12px;
  @media screen and (min-width: 520px) and (max-width: 1060px) {
    font-size: 60px;
    line-height: 66px;
  }
`;

export const Smalltext = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 32px;
  letter-spacing: 0.03em;
  color: #ffffff;
  margin: 4px;
  @media screen and (min-width: 520px) and (max-width: 1060px) {
    font-size: 34px;
    line-height: 43px;
  }
`;
