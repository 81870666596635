import styled from "styled-components";
import font from "../../constants/fonts/font";

interface ContentProp {
  breakPoint?: string;
  height?: string;
  width?: string;
  zIndex?: string;
}

export const DesktopView = styled.div<ContentProp>`
  display: block;
  width: 100%;
  height: ${(props) => props.height || "auto"};
  @media (max-width: ${(props) => props.breakPoint || "1060px"}) {
    display: none;
  }
`;

export const MobileContent = styled.div<ContentProp>`
  display: none;
  @media (max-width: ${(props) => props.breakPoint || "1060px"}) {
    display: block;
    width: ${(props) => props.width || "auto"};
    z-index: ${(props) => props.zIndex};
  }
`;
