import { useEffect, useRef, useState } from "react";
import gsap from "gsap";
const shortid = require("shortid");
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import anime from "animejs";
import {
  ContentSection,
  Description,
  Dot,
  DotsContainer,
  NewsCard,
  NewsCardContainer,
  ProductPageNewsContainer,
  PublishDate,
} from "./styles";
import { SeeHowButton } from "../see-how-button/index-ls";
import Link from "next/link";
import { useIsomorphicLayoutEffect } from "../../../pages";
import { LandingPageTextAnimation } from "../landing-pages-text-animation";
import { useTranslation } from "next-i18next";
import Image from "next/image";
gsap.registerPlugin(ScrollTrigger);

interface ProductPageNewsProps {
  news: any[];
  id?: string;
  firstLineText: string;
  secondLineText: string;
}

export const ProductPageNews = (props: ProductPageNewsProps) => {
  const { t } = useTranslation("common");
  const textAnimeRef = useRef<any>(null);
  const [showText, setShowText] = useState(false);
  useIsomorphicLayoutEffect(() => {
    let matchMedia = gsap.matchMedia();

    function handleMedia(mediaContext: any) {
      const { isDesktop, isMobile } = mediaContext.conditions;
      if (isMobile) {
        let timeline: any;
        let timelineTwo: any;
        let scrollTriggerOne: any;
        let scrollTriggerTwo: any;
        timelineTwo = gsap.timeline({
          paused: true,
        });

        timeline = gsap.timeline({
          paused: true,
        });

        for (let i = 0; i < props.news.length; i++) {
          if (i != props.news.length - 1) {
            timeline.to(`.product-page-news-card-${i}`, {
              zIndex: -1,
              duration: 1,
            });
          }
        }

        scrollTriggerOne = ScrollTrigger.create({
          trigger: ".product-page-news-container",
          start: "top 1px",
          end: `+=${window.innerHeight * 2}px`,
          pin: true,
          scrub: true,
          //preventOverlaps: true,
          //fastScrollEnd: true,
          invalidateOnRefresh: true,
          pinSpacing: true,
          animation: timeline,
          // anticipatePin: 1,
        });

        scrollTriggerTwo = ScrollTrigger.create({
          trigger: ".product-page-news-container",
          start: "top center",
          end: `+=50%`,
          invalidateOnRefresh: true,
          animation: timelineTwo,
          onEnter: () => {
            setShowText(true);
            textAnimeRef.current = anime.timeline({ loop: false }).add({
              targets: ".letter-itn",
              translateX: [40, 0],
              translateZ: 0,
              opacity: [0, 1],
              easing: "easeOutExpo",
              duration: 600,
              markers: true,
              delay: (el, i) => 350 + 10 * i,
            });
          },
        });
      } else {
        matchMedia.revert();
      }
    }

    matchMedia.add(
      {
        isMobile: `(max-width: 1060px)`,
        isDesktop: `(min-width: 1060px)`,
      },
      handleMedia
    );

    return () => {
      matchMedia.revert();
    };
  }, []);

  const generateNewsCards = () => {
    return props.news?.map((item, index) => {
      return (
        <div key={index}>
          <NewsCard
            // background={item.mobileBackground.data?.attributes.url}
            zIndex={props.news.length - index}
            className={`product-page-news-card-${index}`}
          >
            <Image
              src={item.mobileBackground.data?.attributes.url}
              alt={"news card"}
              fill={true}
              style={{ objectFit: "cover", zIndex: -1 }}
            />
            <PublishDate>{item.publishDate}</PublishDate>
            <Description>{item.description}</Description>
            <SeeHowButton
              text={t("read_more")}
              margin="0px"
              width="138px"
              height="43px"
              fontSize="13px"
              background="linear-gradient(270deg, #000000 -77.99%, rgba(0, 0, 0, 0.17) 100%);"
              naviagteTo={item.navigate}
              target="_blank"
            />
            <DotsContainer>
              {props.news.map((item, idx) => {
                return (
                  <Dot
                    key={shortid.generate()}
                    background={
                      index === idx ? "white" : "rgba(217, 217, 217, 0.3);"
                    }
                  ></Dot>
                );
              })}
            </DotsContainer>
          </NewsCard>
        </div>
      );
    });
  };

  return (
    <ProductPageNewsContainer
      className="product-page-news-container"
      id={props.id}
    >
      <NewsCardContainer className="product-page-news-card-wrapper">
        {generateNewsCards()}
      </NewsCardContainer>
      <ContentSection>
        {showText && (
          <LandingPageTextAnimation
            firstLine={props.firstLineText}
            secondLine={props.secondLineText}
            thirdLine={""}
            textAnimationClassName={"itn-ta"}
            textContainerClassName={"itn-ta-container"}
            margin="6px 0px"
            idMargin="6vh 0vw 0vh 0vw"
          />
        )}
      </ContentSection>
    </ProductPageNewsContainer>
  );
};
