import styled from "styled-components";

interface HogCardProps {
  background?: string;
  zIndex: string | number;
}

export const HogContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100vh;
  padding-top: 54px;
  position: relative;

  .itn-tdssa {
    font-size: 36px !important;
    line-height: 1.25 !important;
  }

  .popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;

    .cancel-icon {
      position: absolute;
      right: 30px;
      top: 80px;
      cursor: pointer;
      img {
        width: 16px;
      }
    }
    video {
      width: 98vw;
      transform: translateY(-5vh);
    }
  }
`;

export const HogCardContainer = styled.div`
  width: 95vw;
  height: 58vh;
  border-radius: 5vw;
  position: relative;
  /* overflow: hidden; */
  margin-left: 2.5vw;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .svg-container {
    z-index: 2;
    cursor: pointer;
  }

  .bottom-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const HogCard = styled.div<HogCardProps>`
  width: 95vw;
  height: 58vh;
  border-radius: 2vh;
  top: 0;
  left: 0;
  position: absolute;
  background: ${(props) => `url(${props.background})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: ${(props) => props.zIndex};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 5% 2%;
  z-index: -1;

  img {
    border-radius: 2vh;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 50px; /* Adjust the height as needed */
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.75) 100%
    );
    border-radius: 16px;
  }
`;

export const PublishDate = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: #ffffff;
  margin: 4px 0px;
  @media screen and (min-width: 520px) and (max-width: 1060px) {
    font-size: 20px;
    line-height: 20px;
  }
`;

export const Description = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #ffffff;
  margin: 12px 0px;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  @media screen and (min-width: 520px) and (max-width: 1060px) {
    font-size: 13px;
    line-height: 16px;
  }
  /* text-overflow: ellipsis; */
`;

export const ContentSection = styled.div`
  position: relative;
  z-index: 99;
  display: flex;
  flex-direction: column;
  width: 100%;
  transform: translate(16px, -30px);
  min-height: 142px;
  .user-details {
    color: white;
    display: flex;
    align-items: center;
    /* margin-left: 16px; */

    .name {
      font-size: 22px;
      margin-right: 8px;
    }

    .location {
      margin-left: 8px;
    }
  }

  .hog-title {
    color: white;
    /* margin-left: 16px; */
    line-height: 1.5;
  }
`;

export const DotsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const Dot = styled.div<any>`
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: ${(props) => props.background};
  margin: 4px;
`;

export const LargeText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 46px;
  line-height: 51px;
  letter-spacing: 0.03em;
  color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  @media screen and (min-width: 520px) and (max-width: 1060px) {
    font-size: 60px;
    line-height: 66px;
  }
  .hide-text-product-page-news {
    opacity: 0;
    transform: translateY(100%);
  }
`;
